import React, {useEffect} from 'react';
import Faq from 'react-faq-component';

const FAQList = (props) => {
  useEffect(() => {
    const faq = document.querySelector("section.faq-row.styles_faq-row__2YF3c:nth-child(1) .row-title");
    faq.classList.remove("closed");
    faq.classList.remove("styles_closed__39w54");
    faq.classList.add("expanded");
    faq.classList.add("styles_expanded__3elPy");
  },[])
  const data = {
    rows: []
  }
  for(var i = 0 ; i < props.faqs?.length ; i++){
    data.rows.push(props.faqs[i]);
  }
  return (
      <Faq data={data} />
  )
}
  
export default FAQList