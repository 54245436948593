import React, {useEffect} from 'react';
import { gsap } from "gsap";
import SplitType from 'split-type';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lottie from "lottie-react";

// Section import
import FAQList from "../../components/FAQList"
import { Link } from "gatsby"

import FaqAnimation from "../../assets/faqAnimation.json";

import SlideUp from '../../components/SlideUpOnScroll';



const FAQSection = (props) => {

  useEffect(() => {
    const faqHeading = document.querySelector(".faq-section .section-title");


    gsap.set(faqHeading, { autoAlpha: 1 });

    const splitFaqHeading = new SplitType(faqHeading, {
      type: "words,lines",
      wordsClass: "words",
      linesClass: "lines",
    })

    const tlFaqHeading = gsap.timeline({
      scrollTrigger:{
        trigger: ".faq-section",
        onEnter: () => {
          const elementToAddClassTo = document.querySelector(".faq-section .triger-left-side");
          elementToAddClassTo.classList.add("faq-left-side");
          const body = document.querySelector("body");
          body.classList.add("overflow-hidden");
          const pageWrapper = document.querySelector("#gatsby-focus-wrapper");
          pageWrapper.classList.add("overflow-hidden");
        },
        onLeave: () => {
          const elementToAddClassTo = document.querySelector(".faq-section .triger-left-side");
          elementToAddClassTo.classList.remove("faq-left-side");
          const body = document.querySelector("body");
          body.classList.remove("overflow-hidden");
          const pageWrapper = document.querySelector("#gatsby-focus-wrapper");
          pageWrapper.classList.remove("overflow-hidden");
        },
        onEnterBack: () => {
          const elementToAddClassTo = document.querySelector(".faq-section .triger-left-side");
          elementToAddClassTo.classList.add("faq-left-side");
          const body = document.querySelector("body");
          body.classList.add("overflow-hidden");
          const pageWrapper = document.querySelector("#gatsby-focus-wrapper");
          pageWrapper.classList.add("overflow-hidden");
        },
        onLeaveBack: () => {
          const elementToAddClassTo = document.querySelector(".faq-section .triger-left-side");
          elementToAddClassTo.classList.remove("faq-left-side");
          const body = document.querySelector("body");
          body.classList.remove("overflow-hidden");
          const pageWrapper = document.querySelector("#gatsby-focus-wrapper");
          pageWrapper.classList.remove("overflow-hidden");
        },
      }
    });
    tlFaqHeading.from(splitFaqHeading.words,{
      // duration: 1,
      duration: .8,
      yPercent: 150,
      ease: "Power4.easeInOut",
      rotationX: 0.5,
      rotationY: 0.5,
      z: 0.5,
      delay: .5,
      stagger: {
        amount: 0.1
      }
    });

    const tlFaqListItem = gsap.timeline({
      scrollTrigger:{
        trigger: ".faq-section",
      }
    });
    tlFaqListItem.to(".faq-section .faq-row-wrapper .faq-body .faq-row div", .5,{
      y: 0,
      opacity: 1,
      delay: .5,
      ease: "Power4.easeInOut"
    })

  },[])

  const gsapRefresh = () => {
    ScrollTrigger.refresh(true);
  }

  return (
    <section className="faq-section mt-125 primary-20-bg">
        <div className="container">
            <div className="row" >
              <div className="col-lg-12">
                  <h2 className="h1 section-title extra-spacing">{props.mainTitle}</h2>
              </div>
              <div className="col-lg-12"></div>
            </div>
            <div className="row">
                <div className="col-lg-6 triger-left-side" >
                  <div className="image-block">
                    <SlideUp duration={400} delay={500}>
                      <Lottie animationData={FaqAnimation}/>
                    </SlideUp>
                  </div>
                  <div className="content-block">
                    <p className="pre-title large">{props.preTitle}</p>
                    <p className="description">{props.mainDescription}</p>
                    <div className="btn-block">
                      <Link to="/" className="btn outline-btn" >
                        <span className="btn-text">Ask a Question</span>
                        <i className="btn__icon btn__icon--append icon-arrow-right-up"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2"></div>
                <div className="col-lg-16" >
                    <div onClick={gsapRefresh}>
                      <FAQList 
                        faqs={props.list}
                      />
                    </div>
                    {/* <div className="btn-block read-faq">
                      <Link to="/" className="btn outline-btn" >
                        <span className="btn-text">Read FAQs</span>
                        <i className="btn__icon btn__icon--append icon-arrow-right-up"></i>
                      </Link>
                    </div> */}
                </div>
            </div>
        </div>
    </section>
  )
}

export default FAQSection